<template>
  <TreeSelect
    :load-options="getItems"
    :async="async"
    :loading="loading"
    multiple
    v-bind="$attrs"
    v-on="$listeners"
    :options="options"
    :label="label"
  >
    <template #value-label v-if="$scopedSlots.label">
      <slot name="label" />
    </template>
  </TreeSelect>
</template>

<script>
import TreeSelect from "@/components/core/tree-select";
export default {
  name: "AsyncTreeSelect",
  components: {
    TreeSelect
  },
  props: {
    action: String,
    options: {
      type: Array,
      default: () => []
    },
    optionIds: String,
    async: {
      type: Boolean,
      default: true
    },
    internalSearch: Boolean,
    internalSearchKey: String,
    params: Object,
    label: String
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async getItems({ searchQuery: query, callback }) {
      this.loading = true;
      try {
        if (this.internalSearch) {
          return callback(null, this.search(query, this.$props.options));
        }

        const res = await this.$store.dispatch(this.$props.action, {
          search: query,
          exists_in: this.$props.optionIds || "null",
          ...this.$props.params
        });

        const data = (Array.isArray(res.data) ? res.data : res.data.data).map(
          d => ({
            id: d.id,
            label: d[this.$props.label]
          })
        );

        callback(null, data);
      } catch (err) {
        console.error(err);
        callback(err, null);
      }

      this.loading = false;
    },
    search(query, items) {
      const filteredItems = [];

      for (let index = 0; index < items.length; index++) {
        if (items[index].label.match(new RegExp(query, "i")))
          filteredItems.push(items[index]);
        if (filteredItems.length == 50) break;
      }
      return filteredItems;
    }
  }
};
</script>

<style></style>
