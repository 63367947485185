<template>
  <div class="sales-by-title">
    <EbpCard :loading="loading">
      <b-row class="my-4" align-v="end">
        <!-- Date range -->
        <b-col :md="$isAdmin ? 2 : 3" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("date-range") }}</p>
          <DatePicker
            type="month"
            v-model="date_range"
            :clearable="false"
            range
            :placeholder="$t('date-range')"
            valueType="format"
            format="MMM-YYYY"
          />
        </b-col>
        <b-col md="2" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("books") }}</p>
          <AsyncTreeSelect
            :disabled="loading"
            v-model="titles"
            multiple
            :async="$isAdmin"
            :options="titleList"
            internal-search
            internal-search-key="label"
          />
        </b-col>
        <b-col md="2" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("territories") }}</p>
          <AsyncTreeSelect
            v-model="territories"
            :disabled="loading"
            :options="territoryList"
            :async="false"
            multiple
          />
        </b-col>
        <b-col md="2" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("sales-channels") }}</p>
          <TreeSelect
            :disabled="loading"
            v-model="salesChannels"
            :options="salesChannelList"
            multiple
          />
        </b-col>
        <b-col md="2" class="mb-4 mb-md-0">
          <p class="mb-1">{{ $t("authors") }}</p>
          <AsyncTreeSelect
            :disabled="loading"
            :options="authorList"
            v-model="authors"
            internal-search
            internal-search-key="first_name"
            multiple
            track-by="id"
            label="first_name"
            :async="$isAdmin"
          />
        </b-col>
        <b-col md="2" class="mb-4 mb-md-0" v-if="$isAdmin">
          <p class="mb-1">{{ $t("client") }}</p>
          <AsyncTreeSelect
            v-model="clients"
            :async="$isAdmin"
            action="users/search"
            track-by="id"
            label="name"
            :disabled="loading"
          />
        </b-col>
        <b-col md="1" class="text-right">
          <ebp-button
            v-text="$t('export-csv')"
            @click="exportCSV"
            size="sm"
            :block="isSmallScreen"
            :loading="downloading"
          />
        </b-col>
      </b-row>
    </EbpCard>

    <!-- Table -->
    <div class="sales-by-title-report-table">
      <AdvancedDataTable
        :headers="headers"
        :loadingGlobal="loading"
        :items="records"
        hideActions
        :show-total="true"
        sticky-header="60vh"
        :search="false"
        sortable
        :total-items="records.length"
      >
        <template #cell(title)="{ item }">
          <b v-if="item.is_total">{{ item.title }}</b>
          <template v-else>{{ item.title }}</template>
        </template>

        <template #cell(isbn)="{ item }">
          <b v-if="item.is_total">{{ item.isbn }}</b>
          <template v-else>{{ item.isbn }}</template>
        </template>

        <template #cell(month)="{ item }">
          <b v-if="item.is_total">-</b>
          <template v-else>{{ item.period }}</template>
        </template>

        <template #cell(sales_total)="{ item }">
          <b v-if="item.is_total">{{ item.sales_total }}</b>
          <template v-else>{{ item.sales_total }}</template>
        </template>

        <template #cell(client)="{ item }">
          <b v-if="item.is_total">{{ item.sales_total }}</b>
          <template v-else>{{ item.sales_total }}</template>
        </template>

        <template #cell(royalties)="{ item }">
          <b v-if="item.is_total">{{
            formatCurrency(item.royalties, item.currency, 2, 2)
          }}</b>
          <template v-else>{{
            formatCurrency(item.royalties, item.currency, 2, 2)
          }}</template>
        </template>
      </AdvancedDataTable>
    </div>
  </div>
</template>

<script>
import salesChannelsCategories from "../../fixtures/sales-channel-categories";
import EbpCard from "../../components/core/ebp-card.vue";
import DatePicker from "../../components/core/DatePicker.vue";
import AdvancedDataTable from "../../components/advanced-data-table";
import { format, subMonths, differenceInMonths, parse } from "date-fns";
import { forEach } from "lodash-es";
import TreeSelect from "../../components/core/tree-select";
import addMonths from "date-fns/addMonths";
import FileDownload from "js-file-download";
import AsyncTreeSelect from "@/components/AsyncTreeSelect";

const formatString = "MMM-yyyy";

export default {
  name: "SalesByTitle",
  components: {
    EbpCard,
    DatePicker,
    TreeSelect,
    AdvancedDataTable,
    AsyncTreeSelect
  },
  data() {
    return {
      date_range: [
        format(subMonths(Date.now(), 12), formatString),
        format(Date.now(), formatString)
      ],
      titles: [],
      territories: [],
      salesChannels: [],
      authors: [],
      type: "all",
      showPaid: true,
      showFree: false,
      loading: false,
      groupedData: [],
      rawData: [],
      downloading: false,
      clients: [],
      headers: ["title", "ISBN", "sales_total", "royalties"]
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    async exportCSV() {
      this.downloading = true;

      try {
        const res = await this.$store.dispatch(
          this.$isAdmin ? "reports/exportSalesByTitle" : "reports/exportCSV",
          {
            date_range: this.date_range
              .map(d => format(parse(d, "MMM-yyyy", new Date()), "yyyy-MM"))
              .join(","),
            period: this.date_range
              .map(d => format(parse(d, "MMM-yyyy", new Date()), "yyyy-MM"))
              .join(","),
            authors: this.authors.join(","),
            titles: this.titles.join(","),
            territories: this.territories.join(","),
            sales_channels: this.salesChannels.join(","),
            user_ids: this.clients.join(",")
          }
        );

        FileDownload(res, "sales-by-title.csv");
      } catch (err) {
        console.error(err);
      }

      this.downloading = false;
    },
    async get() {
      this.loading = true;

      try {
        const res = await this.$store.dispatch("reports/salesByTitle", {
          period: this.date_range
            .map(d => format(parse(d, "MMM-yyyy", new Date()), "yyyy-MM"))
            .join(","),
          type: this.type,
          showFree: this.showFree,
          showPaid: this.showPaid,
          user_ids: this.clients.join(","),
          book_details: true
        });

        this.groupedData = res.data;
        this.rawData = res.data_raw;
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }
  },
  computed: {
    titleListIds() {
      return this.titleList.map(t => t.id).join(",");
    },
    records() {
      const bookTotals = {};

      forEach(this.groupedData, data => {
        forEach(data, ({ data }) => {
          forEach(data, (authors, book_id) => {
            // Filter books
            if (this.titles.length && !this.titles.includes(book_id)) return;

            forEach(authors, (sc_categories, author_name) => {
              // Filter authors
              if (this.authors.length && !this.authors.includes(author_name))
                return;

              if (author_name === "title") return;
              forEach(sc_categories, (scs, sc_category) => {
                forEach(scs, (terrs, sc_name) => {
                  // Filter scs
                  if (
                    this.salesChannels.length &&
                    !this.salesChannels.includes(sc_name) &&
                    !this.salesChannels.includes(sc_category)
                  )
                    return;

                  forEach(terrs, ({ sales, royalties }, terr_code) => {
                    // Filter territories
                    if (
                      this.territories.length &&
                      !this.territories.includes(terr_code)
                    )
                      return;
                    if (!bookTotals[book_id]) {
                      bookTotals[book_id] = {
                        sales_total: 0,
                        royalties: 0,
                        title: authors.title.title,
                        isbn: authors.title.isbn
                      };
                    }

                    bookTotals[book_id].sales_total += parseInt(sales || 0);
                    bookTotals[book_id].royalties += parseFloat(royalties);
                  });
                });
              });
            });
          });
        });
      });

      return Object.values(bookTotals);
    },
    dates() {
      const startDate = parse(this.date_range[0], "MMM-yyyy", new Date());
      const endDate = parse(this.date_range[1], "MMM-yyyy", new Date());
      const diffInMonths = Math.abs(differenceInMonths(startDate, endDate)) + 1;

      return new Array(diffInMonths)
        .fill(0)
        .map((_, i) => format(addMonths(startDate, i), "MMM-yyyy"));
    },
    salesChannelsCategories() {
      return ["all", ...salesChannelsCategories];
    },
    titleList() {
      const titles = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(i.data, (d, id) => {
            if (!titles[id])
              titles[id] = {
                id: id,
                label: d.title.title
              };
          });
        });
      });
      return Object.values(titles);
    },
    authorList() {
      const authors = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(i.data, d => {
            forEach(d, (_, k) => {
              if (k === "title") return;
              if (!authors[k])
                authors[k] = {
                  id: k,
                  label: k
                };
            });
          });
        });
      });
      return Object.values(authors);
    },
    salesChannelList() {
      const channels = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(i.data, d => {
            forEach(d, (v, k) => {
              if (k === "title") return;
              forEach(v, (v, n) => {
                if (!n[0]) return;
                if (!channels[n]) {
                  channels[n] = {
                    label: n[0].toUpperCase() + n.slice(1),
                    id: n,
                    children: {}
                  };
                }

                forEach(v, (_, sales_channel_name) => {
                  if (!channels[n].children[sales_channel_name]) {
                    channels[n].children[sales_channel_name] = {
                      label: sales_channel_name,
                      id: sales_channel_name
                    };
                  }
                });
              });
            });
          });
        });
      });
      return Object.values(channels).map(c => ({
        ...c,
        children: Object.values(c.children)
      }));
    },
    territoryList() {
      const territories = {};
      forEach(this.groupedData, i => {
        i.forEach(i => {
          forEach(i.data, d => {
            forEach(d, (v, k) => {
              if (k === "title") return;
              forEach(v, v => {
                forEach(v, v => {
                  forEach(v, (v, n) => {
                    if (!territories[v.name])
                      territories[n] = {
                        label: v.name,
                        id: n
                      };
                  });
                });
              });
            });
          });
        });
      });
      return Object.values(territories);
    },
    chartOptions() {
      return {
        colors: this.$colors,
        xaxis: {
          categories: this.dates
        },
        yaxis: {
          title: {
            text: this.$t("sales-downloads-text"),
            style: {
              fontSize: "14px",
              fontFamily: "SF UI Text"
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: "smooth"
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          },
          style: "hollow"
        },
        chart: {
          toolbar: {
            export: {
              csv: {
                filename: `'sales-by-title'`
              }
            }
          }
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function(val) {
                  return val;
                }
              }
            }
          ]
        }
      };
    }
  },
  watch: {
    type() {
      this.get();
    },
    date_range() {
      this.get();
    },
    clients() {
      this.get();
    }
  }
};
</script>
<style lang="scss">
.sales-by-title-report-table {
  th[role="columnheader"] {
    &:last-child {
      text-align: right;
    }
  }
  td {
    &:last-child {
      text-align: right;
    }
  }
}
</style>
